import React from "react";
import { Row, Col, NavLink } from "reactstrap";
import data from "../../assets/scripts/scripts.jsx";
import FormsContact from "./FormsContact";
import ContainerBg from "../ContainerBg/ContainerBg.jsx";
import "./ContactInfo.css";
import { useLanguage } from "../../assets/translations/Traslations.jsx";
const { IconForms, IconSocialMedia, ContContactInfo } = data;

export default function ContactInfo() {
  const { language } = useLanguage(); // Obtén el idioma actual

  const traductions = (content) => {
    return content[language];
  };
  return (
    <section>
      <ContainerBg Bg={ContContactInfo.bg} hg={"100%"} hgMobile={"100%"}>
        <div className="filter1ContactInfo"></div>
        <Row className="RowNosotros">
          <Col xs={12} md={6} className="Col1section1InfoContact">
            <div className="espaciadosection1InfoContact">
              <Row>
                <Col sx={12} md={12}>
                  <Row>
                    <h2 className="tittlesection1InfoContact">
                      {traductions(ContContactInfo).tittlesection1InfoContact}
                    </h2>
                  </Row>
                  <Row>
                    <Col
                      sx={12}
                      md={12}
                    >
                      <p className="subtittlesection1InfoContact">
                        {
                          traductions(ContContactInfo)
                            .subtittlesection1InfoContact
                        }
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <p className="Parrafosection3Home">
                  {traductions(ContContactInfo).parrafosection3Home}
                </p>
              </Row>
              <Row>
                <Col sx={6} md={2} className="ColIconNosotros">
                  <img src={IconForms.Icon1} alt="CallIcon" />
                </Col>
                <Col sx={6} md={10}>
                  <p className="Parrafosection3Home">
                    {" "}
                    {traductions(ContContactInfo).parrafosection3HomeTel}:
                  </p>
                  <p className="Parrafosection3Home"> +57 (310) 407 2600</p>
                </Col>
              </Row>
              <Row>
                <Col sx={6} md={2} className="ColIconNosotros">
                  <img src={IconForms.Icon2} alt="CorreoIcon" />
                </Col>
                <Col sx={6} md={10}>
                  <p className="Parrafosection3Home"> E-mail: </p>
                  <p className="Parrafosection3Home">
                    comunicaciones@disex.com.co
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sx={6} md={2} className="ColIconNosotros">
                  <img src={IconForms.Icon4} alt="CorreoIcon" />
                </Col>
                <Col sx={6} md={10}>
                  <p className="Parrafosection3Home"> PQRS </p>

                  <a
                    href="https://forms.gle/fR28CnFLJMycoYNd8"
                    target="_blank"
                    className="btn btn-primary btn-block"
                    style={{
                      backgroundColor: "#d3d3d3",
                      border: "none",
                      color: "#222",
                      padding: "5x 10px",
                      borderRadius: "5px",
                      textDecoration: "none",
                      fontSize: "15px",
                      textAlign: "center",
                    }}
                  >

                    {traductions(ContContactInfo).parrafosection3HomePQRS}
                  </a>
                </Col>
              </Row>
              <Row>
                <Col sx={6} md={2} className="ColIconNosotros">
                  <img src={IconForms.Icon3} alt="UbicacionIcon" />
                </Col>
                <Col sx={6} md={10}>
                  <p className="Parrafosection3Home">
                    {traductions(ContContactInfo).parrafosection3HomeUbi}:{" "}
                  </p>
                  <p className="Parrafosection3Home">
                    Cll 17 #43f - 23, Barrio Colombia, Medellín, Colombia
                  </p>
                </Col>
              </Row>
              <Row
                style={{ width: "100%", padding: "0px 0px", margin: "0px 0px" }}
              >
                <Col sx={2} md={4} className="socialMedia"></Col>
                <Col sx={6} md={6} className="Social-media1">
                  <NavLink href="https://co.linkedin.com/company/dise%C3%B1os-exclusivos-s-a-s">
                    <img
                      className="LinkedinIcon"
                      src={IconSocialMedia.LinkedinIcon}
                      alt="LinkedinIcon"
                    />
                  </NavLink>
                </Col>
                <Col sx={6} md={6} className="Social-media2">
                  <NavLink href="https://www.instagram.com/disex.co/">
                    <img
                      className="InstagramIcon"
                      src={IconSocialMedia.InstagramIcon}
                      alt="InstagramIcon"
                    />
                  </NavLink>
                </Col>
              </Row>
            </div>
          </Col>

          {/* info Forms COLUMNA 2*/}
          <Col xs={12} md={6} className="Col2section1InfoContact">
            <div className="espaciado2section1InfoContact">
              <FormsContact />
            </div>
          </Col>
        </Row>
      </ContainerBg>
    </section>
  );
}
