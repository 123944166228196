import { useEffect } from "react";

export default function TermAndCond() {
  useEffect(() => {
    const pdf = require("../../assets/static/POLITICADATOSDISEX2022.pdf");
    window.open(pdf, "_blank");
  }, []);

  return ;
}
