import React from "react";
import "./Chat.css";
import { NavLink } from "react-router-dom";
import ChatIcon from "../../assets/icons/ChatIcon.svg";

export default function Chat() {
  return (
    <NavLink to={"https://api.whatsapp.com/send?phone=573104072600"}>
      <div className="floatWhatsapp">
        <img
          src={ChatIcon}
          alt="Chat_icon"
          className="whatsapp-icon" // Añade una clase para el img
        />
      </div>
    </NavLink>
  );
}
