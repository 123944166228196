import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./Nosotros.css";
import Header from "../../components/Header/Header.jsx";
import Navigate from "../../components/Navigate/Navigate.jsx";
import ContainerBg from "../../components/ContainerBg/ContainerBg.jsx";
import { useLanguage } from "../../assets/translations/Traslations.jsx";

//scripts
import data from "../../assets/scripts/scripts.jsx";
import ContactInfo from "../../components/Forms/ContactInfo.jsx";
const {
  HeaderAcercaNosotros,
  ContNosotrosSection1,
  ContNosotrosSection2,
  ContNosotrosSection4,
} = data;

export default function Nosotros() {

  const { language } = useLanguage(); // Obtiene el idioma actual

  const translations = (data) => {
    return data[language];
  }

  return (
    <>
      <Navigate />
      <Header
        Banner={translations(HeaderAcercaNosotros).Banner}
        Texto={translations(HeaderAcercaNosotros).Text}
        Subtitulo={translations(HeaderAcercaNosotros).Subtittle}
        TextButton1={translations(HeaderAcercaNosotros).TextButton1}
        navigate1={translations(HeaderAcercaNosotros).Navigate1}
        Button1Classname={translations(HeaderAcercaNosotros).Button1Classname}
        Button2Classname={translations(HeaderAcercaNosotros).Button2Classname}
      />
      {/* SECTION1 */}
      <section>
        <ContainerBg
          Bg={translations(ContNosotrosSection1).img}
          hg={"auto"}
          hgMobile={"auto"}
        >
          <div className="filterNosotrosSection1"></div>
          <div className="espaciadoContSection1Nosotros">
            <Row>
              <Col xs={12} md={12} className="ColSection1Nosotros">
                <h2>{translations(ContNosotrosSection1).tittle}</h2>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} className="ColSection1Nosotros track-item">
                <p>{translations(ContNosotrosSection1).P1}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} className="ColSection1Nosotros">
                <p>{translations(ContNosotrosSection1).P2}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} className="ColSection1Nosotros">
                <p>{translations(ContNosotrosSection1).P3}</p>
              </Col>
            </Row>
          </div>
        </ContainerBg>
      </section>

      {/* SECTION 2 */}
      <section>
        <ContainerBg Bg={translations(ContNosotrosSection2).imgBg}>
          <div className="filterNosotrosSection2"></div>
          <div className="espaciadoContSection2Nosotros">
            <Row>
              <Col xs={12} md={6} className="ColSection2Nosotros">
                <h2>{translations(ContNosotrosSection2).tittle}</h2>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} className="ColSection2Nosotros">
              <p>
              <div style={{fontSize:"18px"}} dangerouslySetInnerHTML={{ __html: translations(ContNosotrosSection2).P1}}></div>
              </p>
              </Col>
              <Col xs={12} md={6} className="ColSection2Nosotros">
                <img
                  src={translations(ContNosotrosSection2).img1}
                  alt="Maquina de Tela"
                  className="ImgSection2Nosotros"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} className="ColSection2Nosotros">
                <img
                  src={translations(ContNosotrosSection2).img2}
                  alt="Maquina de Tela"
                  className="ImgSection2Nosotros"
                />
              </Col>
              <Col xs={12} md={6} className="ColSection2Nosotros">
                <p>
                  {/* {ContNosotrosSection2.P2} */}
                  <div style={{fontSize:"18px"}} dangerouslySetInnerHTML={{ __html: translations(ContNosotrosSection2).P2}}></div>
                  </p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} className="ColSection2Nosotros">
                <p>
                  {/* {ContNosotrosSection2.P3} */}
                  <div style={{fontSize:"18px"}} dangerouslySetInnerHTML={{ __html: translations(ContNosotrosSection2).P3}}></div>
                </p>
              </Col>
              <Col xs={12} md={6} className="ColSection2Nosotros">
                <img
                  src={translations(ContNosotrosSection2).img3}
                  alt="Maquina de Tela"
                  className="ImgSection2Nosotros"
                />
              </Col>
            </Row>
          </div>
        </ContainerBg>
      </section>

      {/* SECTION 3 */}
      <section>
        <Container fluid={true} className="VideoNosotros">
          <div className="filterVideoNosotros"></div>
          <iframe
            className="responsive-iframe"
            src="https://www.youtube.com/embed/cwqbpSzhRes?autoplay=1&mute=1&controls=0&loop=1&playlist=cwqbpSzhRes&rel=0&modestbranding=0&showinfo=0&iv_load_policy=3&allowfullscreen=1&loading=auto&cc_load_policy=0&enablejsapi=1&origin=https://disex.com.co&widgetid=1&"
            title="Presentación Corporativa: Diseños Exclusivos SAS"
            referrerpolicy="no-referrer"
            loading="lazy"
          ></iframe>
        </Container>
      </section>
      {/* SECTION4 */}
      <section>
        <ContactInfo Fondo={ContNosotrosSection4.Fondo} />
      </section>
    </>
  );
}
