import React from "react";
import "./404NotFound.css";
import ButtonTransparent from "../../components/Buttons/ButtonTransparent/ButtonTransparent";
import { Container } from "reactstrap";
import Navigate from "../../components/Navigate/Navigate";
import data from "../../assets/scripts/scripts.jsx";
import { useLanguage } from "../../assets/translations/Traslations.jsx";
const { Cont404 } = data;

export default function NotFound() {
  const { language } = useLanguage();

  const translations = (data) => {
    return data[language];
  }

  return (
    <>
      <Navigate />
      <Container fluid="true">
        <div className="NotFound">
          <img src={translations(Cont404).img} alt="Mantenimiento" className="NotFound-img" />
          <h1 className="NotFound-title">{translations(Cont404).title}</h1>
          <p className="NotFound-text">{translations(Cont404).text}</p>
          <ButtonTransparent
            className={translations(Cont404).button1Classname}
            text={translations(Cont404).textButton1}
            navigateto="/"
          />
        </div>
      </Container>
    </>
  );
}
