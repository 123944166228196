import React from "react";
import { Container } from "reactstrap";
import "./PaqueteC.css";
// import Preguntas from "../../components/Preguntas/Preguntas";
import Header from "../../components/Header/Header";
import TimeLine from "../../components/TimeLine/TimeLine";
// import CardsBlog from "../../components/Cards/CardsBlog/CardsBlog.jsx";
// import PaqCompleto from "../../assets/media/PC/DiseñoPC.jpg";
import data from "../../assets/scripts/scripts";
import Navigate from "../../components/Navigate/Navigate";
import ContainerBg from "../../components/ContainerBg/ContainerBg";
import { Row, Col } from "reactstrap";
import ContactInfo from "../../components/Forms/ContactInfo";
import { useLanguage } from "../../assets/translations/Traslations";
const { HeaderPaqueteCompleto, ContSection1PaqCom, Section2PaqCom,Tittlesection1PC } = data;

export default function PaqueteC() {
  function bgCol(bg) {
    const Fondo = bg;
    return {
      backgroundImage: `url(${Fondo})`,
    };
  }

  const { language } = useLanguage();

  const translations = (data) => {
    return data[language];
  }

  return (
    <>
      <section>
        <Navigate />
        <Header
          Banner={translations(HeaderPaqueteCompleto).Banner}
          Texto={translations(HeaderPaqueteCompleto).Texto}
          Subtitulo={translations(HeaderPaqueteCompleto).Subtitulo}
          Descripcion={translations(HeaderPaqueteCompleto).Descripcion}
          TextButton1={translations(HeaderPaqueteCompleto).TextButton1}
          TextButton2={translations(HeaderPaqueteCompleto).TextButton2}
          Button1Classname={translations(HeaderPaqueteCompleto).Button1Classname}
          Button2Classname={translations(HeaderPaqueteCompleto).Button2Classname}
          navigate1={translations(HeaderPaqueteCompleto).Navigate1}
        />
      </section>
      {/* SECTION 1 */}
      <section>
        <ContainerBg Bg={ContSection1PaqCom.bg} hg={"100%"} hgMobile={"100%"}>
          <Container fluid="true">
            <div className="filterSection1PC"></div>
            <h2 className="Tittlesection1PC">{translations(Tittlesection1PC).tittle}</h2>
            <TimeLine />
          </Container>
        </ContainerBg>
      </section>
      {/* SECTION 2 */}
      <section>
        <ContainerBg Bg={translations(Section2PaqCom).img} hg={"100vh"} hgMobile={"100vh"}>
        <div className="filterSection2PaqCom"></div>
          <Row className="TittleSection2PaqCom">
            <Col sm={12} md={12}>
              <h2>{translations(Section2PaqCom).tittle}</h2>
            </Col>
          </Row>
          
          <Row className="Row1Section2PaqCom">
            <Col sm={12} md={3} className="ColSection2PaqCom">
              <div
                className="ImgBgColPaqCom"
                style={bgCol(translations(Section2PaqCom).row1.col1.img)}
                alt="ImgBgCol"
              />
              <div className="CapaTextoSection2PaqCom">
                <h3>{translations(Section2PaqCom).row1.col1.text}</h3>
              </div>
              
            </Col>

            <Col sm={12} md={3} className="ColSection2PaqCom">
              <div
                className="ImgBgColPaqCom"
                style={bgCol(translations(Section2PaqCom).row1.col2.img)}
                alt="ImgBgCol"
              />
              <div className="CapaTextoSection2PaqCom">
                <h3>{translations(Section2PaqCom).row1.col2.text}</h3>
              </div>
            </Col>

            <Col sm={12} md={3} className="ColSection2PaqCom">
              <div
                className="ImgBgColPaqCom"
                style={bgCol(translations(Section2PaqCom).row1.col3.img)}
                alt="ImgBgCol"
              />
              <div className="CapaTextoSection2PaqCom">
                <h3>{translations(Section2PaqCom).row1.col3.text}</h3>
              </div>
            </Col>
            <Col sm={12} md={3} className="ColSection2PaqCom">
              <div
                className="ImgBgColPaqCom"
                style={bgCol(translations(Section2PaqCom).row1.col4.img)}
                alt="ImgBgCol"
              />
              <div className="CapaTextoSection2PaqCom">
                <h3>{translations(Section2PaqCom).row1.col4.text}</h3>
              </div>
            </Col>
          </Row>
          {/* </div> */}
        </ContainerBg>
      </section>
      <section>
        <ContactInfo/>
      </section>
    </>
  );
}
