import React, { useEffect, useState } from "react";
import "./TimeLine.css";
import data from "../../assets/scripts/scripts";
import { useLanguage } from "../../assets/translations/Traslations";


export default function TimeLine() {

  const { language } = useLanguage();



  const translations = (data) => {
    return data[language];
  }

  const [lastScrollTop, setLastScrollTop] = useState(0);
  const { TimelineData } = data;

  // useEffect(() => {
  //   translations(TimelineData);
  //   console.log("TimelineData", TimelineData);
  // }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop;
      const items = document.querySelectorAll(".timeline li");

      items.forEach((item) => {
        if (isElementInViewport(item)) {
          if (scrollTop > lastScrollTop) {
            item.classList.add("in-view");
          } else {
            item.classList.remove("in-view");
          }
        }
      });

      setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
    };

    const isElementInViewport = (el) => {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    };

    window.addEventListener("load", handleScroll);
    window.addEventListener("resize", handleScroll);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("load", handleScroll);
      window.removeEventListener("resize", handleScroll);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);

  return (
      <div className="timeline">
        {/* <div> */}
          <ul>
            {TimelineData.map((item, index) => (
              <li key={index}>
                <div>
                  <span>
                  {/* {translations(item).Year} */}
                  <h2>{translations(item).Num}</h2>
                  <p>{translations(item).Content}</p>
                  <img src={translations(item).Icon} alt="Icons" className="IconsTimeLine" />
                  </span>
                </div>
              </li>
            ))}
          </ul>
        {/* </div> */}
      </div>
  );
}
