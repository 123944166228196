import React from "react";
import "./Home.css";
import Header from "./components/Header/Header.jsx";
import homeData from "./assets/scripts/scripts.jsx";
import ButtonTransparent from "./components/Buttons/ButtonTransparent/ButtonTransparent.jsx";
import { Container, Row, Col } from "reactstrap";
import ContactInfo from "./components/Forms/ContactInfo.jsx";
import CardsInfo from "./components/Cards/CardsInfo/CardsInfo.jsx";
import Navigate from "./components/Navigate/Navigate.jsx";
import { useLanguage } from "./assets/translations/Traslations.jsx"; // Importa el contexto
import ContainerBg from "./components/ContainerBg/ContainerBg.jsx";

export default function Home() {
  const { language } = useLanguage(); // Obtén el idioma actual

  const {
    ContHomeHeader,
    ContHomeSection1,
    ContHomeSection2,
    ContHomeSection3,
    ContHomeSection4,
  } = homeData; // Selecciona el contenido en el idioma actual

  // const content = ContHomeHeader[language]; // Selecciona el contenido en el idioma actual

  // const traductions(ContHomeSection1) = ContHomeSection1[language]; // Selecciona el contenido en el idioma actual

  const traductions = (content) => {
    return content[language];
  };

  return (
    <>
      <Navigate />
      <Header
        Banner={traductions(ContHomeHeader).Banner}
        Texto={traductions(ContHomeHeader).Texto}
        Subtitulo={traductions(ContHomeHeader).Subtitulo}
        TextButton1={traductions(ContHomeHeader).TextButton1}
        TextButton2={traductions(ContHomeHeader).TextButton2}
        Button1Classname={traductions(ContHomeHeader).Button1Classname}
        Button2Classname={traductions(ContHomeHeader).Button2Classname}
        navigate1={traductions(ContHomeHeader).Navigate1}
        navigate2={traductions(ContHomeHeader).Navigate2}
      />

      {/* SECCIÓN 1 */}
      <section>
        <ContainerBg
          Bg={traductions(ContHomeSection1).bg}
          hg={"100vh"}
          hgMobile={"100vh"}
          className="bgimgsection1Home"
        >
          <div className="filter1">
            <Container fluid={true} className="ContainerSeccion1Home">
              <Row>
                <Col sm={12} md={12} className="ColPrendaHome">
                  <h2 className="tittle1seccion1Home">
                    {traductions(ContHomeSection1).tittle}
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12} className="ColPrendaHome2">
                  <p className="Parrafoseccion1Home">
                    {traductions(ContHomeSection1).parrafo}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12} className="ColPrendaHome">
                  <ButtonTransparent
                    className={traductions(ContHomeSection1).button1.classname}
                    text={traductions(ContHomeSection1).button1.text}
                    navigateto={
                      traductions(ContHomeSection1).button1.navigateto
                    }
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </ContainerBg>
      </section>

      {/* SECCIÓN 2 */}
      <section>
        <ContainerBg hg={"100vh"} hgMobile={"100vh"}>
          <div className="filter2"></div>
          <Row style={{ padding: "0px 0px", margin: "0px 0px" }}>
            <Col
              xs={12}
              md={6}
              style={{ padding: "0px 0px", margin: "0px 0px" }}
            >
              <ContainerBg
                Bg={traductions(ContHomeSection2).bgDivT}
                hg={"100%"}
                hgMobile={"50vh"}
              >
                <Row
                  style={{
                    padding: "0px 0px",
                    margin: "0px 0px",
                    height: "50%",
                    width: "100%",
                  }}
                >
                  <Col sx={12} md={12} className="Col1Divtextil">
                    <h2 className="tittle1section2Home">
                      {traductions(ContHomeSection2).tittle1 ===
                      "División Textil" ? (
                        <>
                          División <br /> textil
                        </>
                      ) : (
                        <>
                          Textile <br /> Division
                        </>
                      )}

                      {/* División <br /> Textil */}
                    </h2>
                  </Col>
                </Row>

                <Row
                  style={{
                    padding: "0px 0px",
                    margin: "0px 0px",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <Col sx={12} md={12} className="Col2Divtextil">
                    <ButtonTransparent
                      // text="Quiero más información"
                      text={traductions(ContHomeSection2).button1.text}
                      navigateto={
                        traductions(ContHomeSection2).button1.navigateto
                      }
                      className={
                        traductions(ContHomeSection2).button1.classname
                      }
                    />
                  </Col>
                </Row>
              </ContainerBg>
            </Col>
            <Col
              xs={12}
              md={6}
              style={{ padding: "0px 0px", margin: "0px 0px" }}
            >
              <ContainerBg
                Bg={traductions(ContHomeSection2).bgPaqC}
                className="bgPaqCom"
                hg={"100%"}
                hgMobile={"50vh"}
              >
                <Row
                  style={{
                    padding: "0px 0px",
                    margin: "0px 0px",
                    height: "50%",
                    width: "100%",
                  }}
                >
                  <Col sx={12} md={12} className="Col1Divtextil">
                    <h2 className="tittle1section2Home">
                      {/* Paquete <br /> Completo */}
                      {traductions(ContHomeSection2).tittle2 ===
                      "Paquete Completo" ? (
                        <>
                          Paquete <br /> Completo
                        </>
                      ) : (
                        <>
                          Full <br /> Package
                        </>
                      )}
                    </h2>
                  </Col>
                </Row>
                <Row style={{ padding: "0px 0px", margin: "0px 0px" }}>
                  <Col sx={12} md={12} className="Col2Divtextil">
                    <ButtonTransparent
                      text={traductions(ContHomeSection2).button2.text}
                      navigateto={
                        traductions(ContHomeSection2).button2.navigateto
                      }
                      className={
                        traductions(ContHomeSection2).button2.classname
                      }
                    />
                  </Col>
                </Row>
              </ContainerBg>
            </Col>
          </Row>
        </ContainerBg>
      </section>

      {/* section 3 */}
      <section>
        <ContainerBg
          Bg={traductions(ContHomeSection3).bg}
          hg={"100%"}
          hgMobile={"100%"}
        >
          <div className="filter3"></div>
          <Row style={{ padding: "0px 0px", margin: "0px 0px" }}>
            <Col sm={12} md={12}>
              <Row>
                <Col sm={12} md={12}>
                  <h2 className="tittle1section3Home">
                    {traductions(ContHomeSection3).tittle}
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12}>
                  <p className="subtitle1section3Home">
                    {traductions(ContHomeSection3).paragraph}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="RowCardsInfoHome">
            <Col md={4} sm={12} className="ColCardsInfoHome">
              <CardsInfo
                contentFront={
                  traductions(ContHomeSection3).contInfoCards.card1.contentFront
                }
                contentBack={
                  traductions(ContHomeSection3).contInfoCards.card1.contentBack
                }
                icon={traductions(ContHomeSection3).contInfoCards.card1.icon}
              />
            </Col>
            <Col md={4} sm={12} className="ColCardsInfoHome">
              <CardsInfo
                contentFront={
                  traductions(ContHomeSection3).contInfoCards.card2.contentFront
                }
                contentBack={
                  traductions(ContHomeSection3).contInfoCards.card2.contentBack
                }
                icon={traductions(ContHomeSection3).contInfoCards.card2.icon}
              />
            </Col>
            <Col md={4} sm={12} className="ColCardsInfoHome">
              <CardsInfo
                contentFront={
                  traductions(ContHomeSection3).contInfoCards.card3.contentFront
                }
                contentBack={
                  traductions(ContHomeSection3).contInfoCards.card3.contentBack
                }
                icon={traductions(ContHomeSection3).contInfoCards.card3.icon}
              />
            </Col>
          </Row>
          <Row className="RowCardsInfoHome">
            <Col md={4} sm={12} className="ColCardsInfoHome">
              <CardsInfo
                contentFront={
                  traductions(ContHomeSection3).contInfoCards.card4.contentFront
                }
                contentBack={
                  traductions(ContHomeSection3).contInfoCards.card4.contentBack
                }
                icon={traductions(ContHomeSection3).contInfoCards.card4.icon}
              />
            </Col>
            <Col md={4} sm={12} className="ColCardsInfoHome">
              <CardsInfo
                contentFront={
                  traductions(ContHomeSection3).contInfoCards.card5.contentFront
                }
                contentBack={
                  traductions(ContHomeSection3).contInfoCards.card5.contentBack
                }
                icon={traductions(ContHomeSection3).contInfoCards.card5.icon}
              />
            </Col>
            <Col md={4} sm={12} className="ColCardsInfoHome">
              <CardsInfo
                contentFront={
                  traductions(ContHomeSection3).contInfoCards.card6.contentFront
                }
                contentBack={
                  traductions(ContHomeSection3).contInfoCards.card6.contentBack
                }
                icon={traductions(ContHomeSection3).contInfoCards.card6.icon}
              />
            </Col>
          </Row>
        </ContainerBg>
      </section>

      {/* SECCIÓN 4 */}
      <section>
        <ContactInfo Fondo={ContHomeSection4.fondo} />
      </section>
    </>
  );
}
